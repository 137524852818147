<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.dealerToDealerLoads')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                    v-if="$global.hasPermission('ddloadsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="dd-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ item, index}">
                            {{ (pagination.perPage * (pagination.current-1)) + (index + 1) }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(client_id)="record">
                            {{record.item.client ? record.item.client.company_name : ''}}
                        </template>
                        <template v-slot:cell(from_to_dealer_id)="{item}">
                            <span v-for="i in item.dealer_to_dealer_load_orders">
                                {{(i.from_dealer || {}).name}}
                            </span>
                        </template>
                        <template v-slot:cell(to_dealer_id)="record">
                            {{record.item.to_dealer ? record.item.to_dealer.name : ''}}
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <span v-if="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].from_location">
                                <print-ad :item="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].from_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <span v-if="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].to_location">
                                <print-ad :item="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].to_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(loading_date)="record">
                            <span v-if="record.item.estimated_loading_time">
                                {{$global.utcDateToLocalDate(record.item.estimated_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(status)="record">
                            <re-status :status="record.item.status"></re-status>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')"
                               v-if="record.item.status < 4" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="record.item.status <= 3">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1" :title="$t('button.title.deleteItem')" v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>

                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.dd-table-->

                <div class="dd-operation">
                    <div v-if="operation === 'edit'">
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>

                                <template v-show="global.pendingRequests <= 0">
                                    <b-col md="6" lg="6" sm="12">
                                        <b-row>
                                            <b-col md="6" lg="6" sm="12">
                                                <b-form-group
                                                    :label="$t('input.supplierCarrier')+' *'"
                                                    label-for="supplier_id"
                                                    :invalid-feedback="formErrors.first('supplier_carrier_id')"
                                                >
                                                    <treeselect
                                                        :multiple="false"
                                                        :disabled="formFields.status >= 4"
                                                        :options="dropdowns.supplierCarriers"
                                                        placeholder=""
                                                        v-model="formFields.supplier_carrier_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6" lg="6" sm="12">
                                                <b-form-group
                                                    :label="$t('input.dealer')+' *'"
                                                    label-for="to_dealer_id"
                                                    :invalid-feedback="formErrors.first('to_dealer_id')"
                                                >
                                                    <treeselect
                                                        :multiple="false"
                                                        :disabled="formFields.status >= 4"
                                                        :options="dropdowns.dealers"
                                                        placeholder=""
                                                        v-model="formFields.to_dealer_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('to_dealer_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.toLocation')+' *'"
                                            label-for="location_id"
                                            :invalid-feedback="formErrors.first('location_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :disabled="true"
                                                :options="dropdowns.locations"
                                                placeholder=""
                                                v-model="formFields.to_location_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('location_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="form-group">
                                            <div class="clearfix">
                                                <span class="pull-left">
                                                    <label class="d-block">{{$t('input.order')}}</label>
                                                </span><!-- /.pull-left -->
                                                <span class="pull-left ml-2 mb-1">
                                                    <button class="btn btn-warning btn-sm" v-b-tooltip.hover
                                                            :title="$t('button.title.addNewOrder')" type="button"
                                                            @click="handleAddOrderClick">{{$t('button.add')}}</button>
                                                </span><!-- /.pull-left -->
                                            </div><!-- /.clearfix -->
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.orders"
                                                placeholder=""
                                                v-model="selectedOrderId"
                                                :class="[{'invalid is-invalid': (formErrors.has('order_id'))}]" />
                                            <div class="invalid-feedback">{{formErrors.first('order_id')}}</div>
                                        </div>
                                    </b-col>

                                    <b-col md="6" lg="6" sm="12">
                                        <b-row>
                                            <b-col sm="4" v-if="formFields.status == 3">
                                                <b-form-group
                                                    :label="$t('input.status') + ':'"
                                                    :invalid-feedback="formErrors.first('announce')"
                                                >
                                                    <b-form-checkbox v-model="formFields.announce" name="announce">
                                                        {{$t('title.announce')}}
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="comment"
                                            :invalid-feedback="formErrors.first('comments')">
                                            <b-textarea
                                                id="comment"
                                                v-model="formFields.comments"
                                                type="text"
                                                :state="((formErrors.has('comments') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col>

                                    <b-col sm="12">
                                        <h3 class="text-secondary">{{$t('title.existingOrders')}}</h3>
                                        <hr />

                                        <small class="text-danger" v-if="formErrors.has('orders')">{{formErrors.first('orders')}}</small>
                                        <table class="table table-hover" v-if="editLoad && editLoad.dealer_to_dealer_load_orders">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.orderId')}}</th>
                                                    <th>{{$t('column.dealer')}}</th>
                                                    <th>{{$t('column.brand')}}</th>
                                                    <th>{{$t('column.model')}}</th>
                                                    <th class="text-right">{{$t('column.action')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ilo, index) in editLoad.dealer_to_dealer_load_orders" :class="{'bg-light-danger': _.includes(formFields.deleted_orders, ilo.order.id)}">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order.order_id}}</td>
                                                    <td>{{(ilo.from_dealer || {}).name}}</td>
                                                    <td>{{(ilo.order.brand || {}).title}}</td>
                                                    <td>{{(ilo.order.model || {}).title}}</td>
                                                    <td class="text-right">
                                                        <a-popconfirm title="Are you sure？" @confirm="handleDeleteOrderClick(ilo.order.id)"
                                                                      v-if="!_.includes(formFields.deleted_orders, ilo.order.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                        <template v-if="formFields.new_orders.length <= 0">
                                                            <a class="ml-1"
                                                               v-if="_.includes(formFields.deleted_orders, ilo.order.id) && formFields.new_orders.length <= 0"
                                                               @click="handleUndoDeleteClick(ilo.order.id)"
                                                               :title="$t('button.title.undo')"
                                                               v-b-tooltip.hover>
                                                                <i class="fa fa-undo"></i>
                                                            </a>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h3 class="text-secondary">{{$t('title.newOrders')}}</h3>
                                        <hr/>
                                        <table class="table table-hover" v-if="editLoad && formFields.new_orders.length">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.orderId')}}</th>
                                                    <th>{{$t('column.dealerLocation')}}</th>
                                                    <th>{{$t('column.dealer')}}</th>
                                                    <th>{{$t('column.action')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ilo, index) in formFields.new_orders">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order_id}}</td>
                                                    <td><print-ad :item="ilo.dealer_location"></print-ad></td>
                                                    <td>{{(ilo.dealer || {}).name}}</td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？" @confirm="handleDeleteNewOrderClick(ilo.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr/>

                                        <b-row class="operation-footer">
                                            <b-col sm="12">
                                                <b-button
                                                    size="sm"
                                                    type="submit"
                                                    variant="primary"
                                                    :disabled="global.pendingRequests > 0"
                                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                                >
                                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                                    <i class="fa fa-save mr-1"></i>
                                                    {{$t('button.save')}}
                                                </b-button>
                                                <b-button variant="warning" class="ml-3"
                                                          size="sm" @click="handleOperationClose()"
                                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </template>
                            </b-row>
                        </form>
                    </div>
                </div><!--/.dd-operation-->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.carriers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCarriers"
                                            placeholder=""
                                            v-model="filters.carriers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.loadingPoint"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.unloadingPoint"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->

            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import qs from "qs"
    import {getAuthUser} from "../../../util/Utils"

    const FORM_STATE = {
        supplier_carrier_id: null,
        to_dealer_id: null,
        to_location_id: null,
        id: null,
        comments: null,
        dealer_to_dealer_load_orders: [],
        status: 2,
        announce: false,
        _method: 'post',
        deleted_orders: [],
        new_orders: [],
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        models:[],
        brands:[],
        loadingPoint:[],
        unloadingPoint:[],
        status:[],
        carriers: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.fromDealer'),
            key: 'from_to_dealer_id',
            sortable: false,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.toDealer'),
            key: 'to_dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'loading_date',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasPermission('ddloadsownview') && self.$global.hasRole('client')
        ? {
            label: self.$t('column.action'),
            class: 'text-right w-100px',
            key: 'action',
        } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.dealerToDealerLoads',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'dealer/to/dealer/loads/client',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    supplierCarriers: [],
                    locations: [],
                    dealers: [],
                    orders: [],
                    countries: [],
                    status: [
                        {id: 1, label: this.$t('title.transportOrder')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.stockExitInTransit')},
                        {id: 7, label: this.$t('title.deliveryRequested')},
                        {id: 8, label: this.$t('title.delivered')},
                    ],
                    loadingPoint:[],
                    unloadingPoint:[],
                    brands:[],
                    models:[],
                },
                show: true,
                editLoad: null,
                selectedOrderId: null,
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getSupplierCarriers();
            this.getDealers();

            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('title.dealerToDealerLoads')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                if(this.editLoad.dealer_to_dealer_load_orders.length === this.formFields.deleted_orders.length) {
                    const errors = {orders: [ this.$t('validation.minimumOrderRequired') ]}
                    this.formErrors = new Error(errors)
                    return false;
                }

                if(this.formFields.supplier_carrier_id) {
                    const supplier = _.find(this.dropdowns.supplierCarriers, {id: this.formFields.supplier_carrier_id})

                    if(supplier.prices.length > 0) {
                        this.formFields.price_id = supplier.prices[0].id;
                        this.formFields.route_id = supplier.prices[0].route.id;
                        this.formFields.price_period_id = supplier.prices[0].price_periods[0].id;
                    }
                }

                try {
                    const response = await request({
                        url: 'dealer/to/dealer/loads/client/update',
                        method: 'post',
                        data: {
                            id: this.formFields.id,
                            supplier_carrier_id: this.formFields.supplier_carrier_id,
                            to_location_id: this.formFields.to_location_id,
                            to_dealer_id: this.formFields.to_dealer_id,
                            price_id: this.formFields.price_id,
                            route_id: this.formFields.route_id,
                            price_period_id: this.formFields.price_period_id,
                            comments: this.formFields.comments,
                            _method: 'post',
                            deleted_orders: this.formFields.deleted_orders,
                            new_orders: this.formFields.new_orders.map((item) => ({id: item.id})),
                            status: this.formFields.status,
                            announce: this.formFields.announce
                        },
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                this.dropdowns.orders = []
                this.formFields.new_orders = []
                this.formFields.deleted_orders = []
                this.operationTitle = this.$t('title.editLoad')
                request({url: `/dealer/to/dealer/loads/client/detail/${id}`})
                .then((response) => {
                    const {data} = response
                    this.editLoad = data
                    this.formFields.id = data.id
                    this.formFields.supplier_carrier_id = (data.supplier_carrier ? data.supplier_carrier.id : null)
                    this.formFields.to_location_id = data.dealer_to_dealer_load_orders[0].to_location.id
                    this.formFields.to_dealer_id = data.to_dealer.id
                    this.formFields.limit = data.qty
                    this.formFields.status = data.status
                    this.getOrders(data)
                    this.getDealerToDealerOrderNearLocationSupplierCarrier(data)
                })
                .catch((error) => {
                    this.handleServerError(error)
                })
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/dealer/to/dealer/loads/client/delete',
                        data: {
                            id: id,
                        },
                    })

                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async getOrders(item) {
                try {
                    const params =  {
                        ids: item.dealer_to_dealer_load_orders.map(i => i.order.id),
                        dealer_location: item.dealer_to_dealer_load_orders[0].to_location.id,
                        client: item.client.id,
                        brand: item.dealer_to_dealer_load_orders[0].order.brand.id,
                        model: item.dealer_to_dealer_load_orders[0].order.model.id,
                        status: [17],
                    }

                    const {data} = await request({
                        method: "post",
                        url: 'dropdowns/orders/dealer/to/dealer/update',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    this.dropdowns.orders = data.map(item => ({
                        id: item.id,
                        label: `${item.order_id} | ${item.vin_number}`,
                        item: item
                    }))
                } catch (e) {
                    console.log(e)
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                    this.dropdowns.loadingPoint = data
                    this.dropdowns.unloadingPoint = data
                } catch (e) {
                    this.dropdowns.locations = []
                    this.dropdowns.loadingPoint = []
                    this.dropdowns.unloadingPoint = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data

                } catch (e) {
                    console.log(e)
                    this.dropdowns.dealers = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getDealerToDealerOrderNearLocationSupplierCarrier(orders) {
                this.dropdowns.supplierCarriers = [];

                let from_location = null; let to_location = null; const brands = []; const models = []; const ids = [];
                _.map(orders.dealer_to_dealer_load_orders, (val) => {
                    from_location = val.from_location
                    to_location = val.to_location
                    brands.push(val.order.brand.id)
                    models.push(val.order.model.id)
                    ids.push(val.order.id)
                })

                try {
                    const response = await request({
                        url: '/dropdowns/orders/matched/location/dd/carrier/suppliers',
                        method: "post",
                        data: {
                            orders: [{
                                id: 1,
                                from_location: from_location,
                                brands: _.uniq(brands),
                                models: _.uniq(models),
                                to_location: to_location,
                                ids: ids
                            }],
                            created_at: orders.created_at
                        }
                    })

                    const {data} = response
                    _.map(data, (group, index) => {
                        this.dropdowns.supplierCarriers = group.suppliers
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            validateOrderError() {
                const errors = {}
                let hasError = false
                if(!this.selectedOrderId) {
                    errors.order_id = [this.$t('validation.required')]
                    hasError = true
                }

                if(_.find(this.editLoad.dealer_to_dealer_load_orders, {id: this.selectedOrderId})) {
                    errors.order_id = [this.$t('validation.duplicate')]
                    hasError = true
                }

                this.formErrors = new Error(errors)
                return hasError
            },
            handleAddOrderClick() {
                if(this.validateOrderError())
                    return false

                this.formFields.new_orders.push(_.find(this.dropdowns.orders, {id: this.selectedOrderId}).item)
                this.selectedOrderId = null
            },
            handleDeleteNewOrderClick(orderId) {
                const orders = this.formFields.new_orders
                orders.splice(orderId, 1)
                this.formFields.new_orders = orders
            },
            handleDeleteOrderClick(orderId) {
                this.formFields.deleted_orders.push(orderId)
            },
            handleUndoDeleteClick(orderId) {
                const orders = this.formFields.deleted_orders
                orders.splice(orderId, 1)
                this.formFields.deleted_orders = _.compact(orders)
            },
            hasListAccess() {
                return (this.$global.hasPermission('ddloadsownview') && this.$global.hasRole('client'))
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.editLoad = null
                this.selectedOrderId = null
                this.dropdowns.orders = []
                this.formFields.deleted_orders = []
                this.formFields.new_orders = []
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .w-220{
        width: 220px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx{
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn{
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .min-height-375{
        min-height: 375px;
    }

    .w-100px{
        width: 100px;
    }
</style>
